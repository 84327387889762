/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [menu, setMenu] = useState(false);

  if (typeof document !== `undefined`) {
    if (menu) {
      document.getElementsByTagName("body")[0].style = "overflow: hidden";
    } else {
      document.getElementsByTagName("body")[0].style = "overflow: visible";
    }
  }

  return (
    <AppContext.Provider
      value={{
        menu,
        setMenu
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
